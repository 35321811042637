import React, { ComponentType, useEffect, useMemo } from 'react'
import { BREAKPOINTS, Badge, Box, Header, Token, VStack } from '@revolut/ui-kit'
import { PageWrapper } from '@src/components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'

import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Basic } from './editComponents/Basic'
import { Questions } from './editComponents/Questions'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { Audience } from './editComponents/Audience'
import { Notifications } from './editComponents/Notifications'
import { Review } from './editComponents/Review'
import { PageBody } from '@src/components/Page/PageBody'
import Form from '@src/features/Form/Form'
import { engagementSurveyFormRequests } from '@src/api/engagement'
import { FormActions } from './editComponents/FormActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { isStepDone } from './helpers'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'

const formTabs: (TabBarTableNavigationInterface & {
  component: ComponentType<any>
  key: EngagementEditStep
})[] = [
  {
    title: 'Basic details',
    key: 'basic',
    component: Basic,
  },
  {
    title: 'Questions',
    key: 'questions',
    component: Questions,
  },
  {
    title: 'Audicence',
    key: 'audience',
    component: Audience,
  },
  {
    title: 'Notifications',
    key: 'notifications',
    component: Notifications,
  },
  {
    title: 'Review',
    key: 'review',
    component: Review,
  },
]

const DEFAULT_RESPONSE_DEADLINE = 2

const EngagementFormContent = () => {
  const { id, step } = useParams<{ id?: string; step: EngagementEditStep }>()
  const { values } = useLapeContext<EngagementSurveyInterface>()
  const isOnboarding = isOnboardingPath()

  useEffect(() => {
    if (!id) {
      values.response_deadline_days = DEFAULT_RESPONSE_DEADLINE
    }
  }, [])

  const title = useMemo(() => {
    if (!id) {
      return 'Create new survey'
    }
    return values?.title ? values.title : 'Edit survey'
  }, [])

  const tabs = useMemo(() => {
    return formTabs.map((tab, idx) => {
      const path = pathToUrl(
        isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE
          : ROUTES.FORMS.ENGAGEMENT.EDIT,
        { id, step: tab.key },
      )

      return {
        ...tab,
        path,
        disabled: !values.id,
        url: path,
        preTitle: (
          <Box mr="s-8">
            <Badge
              useIcon={isStepDone(tab.key, values) ? 'Check' : undefined}
              size={16}
              bg={
                isStepDone(tab.key, values)
                  ? Token.color.lightGreen
                  : step === tab.key
                  ? Token.color.greyTone50
                  : Token.color.greyTone10
              }
            >
              {!isStepDone(tab.key, values) ? idx + 1 : null}
            </Badge>
          </Box>
        ),
      }
    })
  }, [id, step])
  return (
    <PageWrapper>
      <Header variant="item">
        <Header.BackButton
          onClick={() =>
            goBack(
              isOnboarding && values.id
                ? pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.SETUP, {
                    id: values.id,
                  })
                : ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD,
            )
          }
          aria-label="Back"
        />
        <Header.Title use="h1">{title}</Header.Title>
        <Header.Description>Survey details</Header.Description>
      </Header>
      <Box mb="s-16">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.key} path={tab.path}>
            <PageBody maxWidthMd={BREAKPOINTS.md}>
              <VStack gap="s-16">
                <tab.component />
              </VStack>
            </PageBody>
            <PageActions>
              <FormActions currentStep={tab.key} />
            </PageActions>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export const EngagementForm = () => {
  return (
    <Form api={engagementSurveyFormRequests}>
      <EngagementFormContent />
    </Form>
  )
}
