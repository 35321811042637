import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import debounce from 'lodash/debounce'
import kebabCase from 'lodash/kebabCase'
import { matchSorter } from 'match-sorter'
import { Virtuoso } from 'react-virtuoso'
import {
  Box,
  BoxProps,
  Color,
  Dots,
  Dropdown,
  Flex,
  Text,
  Spacer,
  Link as UILink,
  HStack,
  Tag,
  Token,
  Icon,
} from '@revolut/ui-kit'
import styled from 'styled-components'
import useFetchOptions, { AsyncState } from '@components/Inputs/hooks/useFetchOptions'
import { ExclamationMarkOutline, Plus, ChevronDown, ChevronUp } from '@revolut/icons'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { SelectorType } from '@src/interfaces/selectors'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import { ClearButton } from '@components/Inputs/partials/ClearButton/ClearButton'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { selectorKeys, selectorToCreateURL, selectorToItemName } from '@src/constants/api'
import {
  RadioSelectOption,
  createNewKey,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { UseGetSelectorsQueryOptions } from '@src/api/selectors'

type WrapperProps = {
  disabled?: boolean
}

const DROPDOWN_ITEM_HEIGHT = 46

const Pill = styled(Tag)<{ disabled?: boolean }>`
  height: 24px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:not(:last-child) {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`

const Wrapper = styled(Box)`
  ${ClearButton} {
    display: none;
  }

  &:hover:not([aria-disabled='true']),
  &:focus-within {
    ${ClearButton} {
      display: block;
    }
  }
`

export interface NewMultiSelectGroupInterface<T> {
  title: string
  options: RadioSelectOption<T>[]
}

export interface NewMultiSelectProps<T>
  extends WrapperProps,
    Omit<BoxProps, 'value' | 'onChange'> {
  placeholder?: string
  selector?: SelectorType
  options?: RadioSelectOption<T>[]
  pathToId?: string
  forceAsyncState?: AsyncState
  value?: RadioSelectOption<T>[]
  onChange?: (selectedOptions: RadioSelectOption<T>[]) => void
  onSearch?: (search: string) => void
  hasError?: boolean
  disabled?: boolean
  message?: React.ReactNode
  name?: string
  allowCustomOptions?: boolean
  children?: (option: RadioSelectOption<T>) => React.ReactNode
  customOptionLabel?: string
  groups?: NewMultiSelectGroupInterface<T>[]
  clearable?: boolean
  canCreateNew?: boolean
  dropdownItemHeight?: number
  disableSorting?: boolean
  useQuery?: boolean
  useQueryOptions?: UseGetSelectorsQueryOptions<T>
  showCreateNewButton?: boolean
  /** Keys match-sorter will use for the ranking */
  matchSorterKeys?: string[]
}

// unless we don't switch to the UI kit MultiSelect, we use the filtering method which UI kit uses internally for dropdowns
const defaultSearch = <T extends {}>(
  input: string,
  options: RadioSelectOption<T>[],
  disableSorting?: boolean,
  matchSorterKeys?: string[],
): RadioSelectOption<T>[] =>
  matchSorter(options, input, {
    keys: matchSorterKeys || ['label'],
    sorter: disableSorting ? rankedItems => rankedItems : undefined,
  })

const dropdownId = 'multiselect_dd'
const idField = 'id'

enum ActionType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  REPLACE = 'REPLACE',
  CLEAR = 'CLEAR',
}

type Action<T> = {
  type: ActionType
  data: RadioSelectOption<T> | RadioSelectOption<T>[]
  triggerUpdate: boolean
}

type State<T> = {
  value: Map<string | number, RadioSelectOption<T>>
  triggerUpdate: boolean
}
type ValueReducer<T> = (state: State<T>, action: Action<T>) => State<T>

const getValueReducer = <T,>(): ValueReducer<T> => {
  return (state: State<T>, action: Action<T>): State<T> => {
    const data: RadioSelectOption<T>[] = isArray(action.data)
      ? action.data
      : [action.data]
    const newState = action.type === ActionType.REPLACE ? new Map() : new Map(state.value)

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const getId = (option: RadioSelectOption<T>): string | number => option.value[idField]

    switch (action.type) {
      case ActionType.ADD:
        data.forEach(option => newState.set(getId(option), option))
        break
      case ActionType.REMOVE:
        data.forEach(option => newState.delete(getId(option)))
        break
      case ActionType.REPLACE:
        data.forEach(option => newState.set(getId(option), option))
        break
      case ActionType.CLEAR:
        newState.clear()
        break
    }
    return { value: newState, triggerUpdate: action.triggerUpdate }
  }
}

type NewMultiSelectReducerValue<T> = T | { id: string; name: string; color?: Color }

const NewMultiSelect = <T extends { id: number | string; color?: Color }>({
  placeholder,
  selector = null,
  onChange = () => {},
  onSearch,
  forceAsyncState,
  disabled,
  value: propValue,
  hasError,
  message,
  name,
  allowCustomOptions,
  children,
  customOptionLabel,
  groups,
  clearable,
  canCreateNew,
  dropdownItemHeight,
  disableSorting,
  matchSorterKeys,
  useQuery,
  useQueryOptions,
  showCreateNewButton,
  ...props
}: NewMultiSelectProps<T>) => {
  const fetchState = useFetchOptions<T>(
    selector,
    useQuery,
    undefined,
    undefined,
    useQueryOptions,
  )
  const { asyncState: fetchAsyncState } = fetchState
  const asyncState = forceAsyncState || fetchAsyncState
  const options = props.options || fetchState.options
  const useBuiltInSearch = !onSearch
  const label = kebabCase(placeholder)
  const optionHeight = dropdownItemHeight || DROPDOWN_ITEM_HEIGHT

  // Had to use this approach because lape doesn't work with Set
  const [{ value, triggerUpdate }, dispatchValue] = useReducer<
    ValueReducer<NewMultiSelectReducerValue<T>>
  >(getValueReducer<NewMultiSelectReducerValue<T>>(), {
    value: new Map(),
    triggerUpdate: false,
  })

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const [fieldWidth, setFieldWidth] = useState<string>('0px')
  const fieldRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isArray(propValue)) {
      dispatchValue({ type: ActionType.REPLACE, data: propValue, triggerUpdate: false })
    }
  }, [propValue])

  useEffect(() => {
    if (triggerUpdate) {
      onChange(Array.from(value.values()) as RadioSelectOption<T>[])
    }
  }, [triggerUpdate, value])

  useEffect(() => {
    if (!fieldRef.current) {
      return
    }
    const style = getComputedStyle(fieldRef.current!)
    setFieldWidth(style.width)
  }, [fieldRef.current])

  const getId = (option: RadioSelectOption<T>): string | number => option.value[idField]

  const renderIcon = (): React.ReactNode => {
    if (asyncState === 'pending') {
      return (
        <Box mr="s-8">
          <Dots size={16} color="grey-tone-20" />
        </Box>
      )
    }
    if (asyncState === 'failed') {
      return (
        <Tooltip text="Failed to fetch options" placement="bottom">
          <ExclamationMarkOutline
            cursor="default"
            color="error"
            data-testid="multiselect-icon"
          />
        </Tooltip>
      )
    }
    return null
  }

  const checkFilter = (option: RadioSelectOption<T>): boolean => {
    if (!option.label) {
      return false
    }
    const isAlreadyAdded = value.has(getId(option))

    return !isAlreadyAdded
  }

  const ITEMS_TO_DISPLAY = canCreateNew ? 5 : 6

  const removeOption = (option: RadioSelectOption<T>) => {
    dispatchValue({
      type: ActionType.REMOVE,
      data: option,
      triggerUpdate: true,
    })
  }

  const valueArr = useMemo(
    () => (isArray(propValue) ? propValue : Array.from(value.values())),
    [propValue, value],
  )

  const colors = useMemo(() => {
    const colorsMap = new Map<number | string, Color>()

    valueArr.forEach(option => {
      // use color from value or use color from option
      const color = option.value.color
        ? option.value.color
        : options.find(item => item.value.id === option.value.id)?.value?.color
      if (color) {
        colorsMap.set(option.value.id, color)
      }
    })

    return colorsMap
  }, [options, valueArr])

  const renderValue = () => {
    if (isEmpty(valueArr)) {
      return (
        <Text color={Color.GREY_TONE_50} id={label} fontSize="primary" lineHeight={1.5}>
          {placeholder || 'Select'}
        </Text>
      )
    }

    return (
      <Box width={message ? '85%' : '95%'}>
        <Text
          color={Color.GREY_TONE_50}
          id={label}
          fontSize="small"
          mt="-4px"
          mb="s-8"
          use="div"
        >
          {placeholder}
        </Text>
        <Flex flexWrap="wrap" mr="s-8" mb="s-2" mt="s-2" minWidth={0} maxWidth="100%">
          {valueArr.map(option => (
            <Pill
              use="button"
              type="button"
              key={getId(option as RadioSelectOption<T>)}
              variant="outlined"
              disabled={disabled}
              color={colors.get(option.value.id) || Color.DEEP_GREY}
              onClear={
                !disabled
                  ? () => {
                      removeOption(option as RadioSelectOption<T>)
                    }
                  : undefined
              }
              onClick={e => {
                if (!disabled) {
                  removeOption(option as RadioSelectOption<T>)
                }
                e.stopPropagation()
              }}
              data-testid="multiselect-pill"
              // we clear the button title to avoid breaking tests when we getByRole, empty string does not work
              labelButtonClear=" "
            >
              {option.label}
            </Pill>
          ))}
        </Flex>
      </Box>
    )
  }

  const renderOptions = (items: RadioSelectOption<T>[]) => {
    const filtered = defaultSearch<T>(
      search,
      items,
      disableSorting,
      matchSorterKeys,
    ).filter(item => checkFilter(item))

    if (showCreateNewButton) {
      filtered.unshift({
        label: (
          <Flex alignItems="center" color={Token.color.blue}>
            <Icon name="Plus" size={16} />
            &nbsp; Create new
          </Flex>
        ),
        value: { id: createNewKey } as T,
      })
    }

    return (
      <Virtuoso
        data={filtered}
        style={{
          height: `${
            (filtered.length > ITEMS_TO_DISPLAY ? ITEMS_TO_DISPLAY : filtered.length) *
            optionHeight
          }px`,
        }}
        itemContent={(_, option) => (
          <Dropdown.Item
            key={option.value.id}
            onClick={e => {
              if (option.value.id === createNewKey) {
                onChange([option])
                return
              }
              dispatchValue({ type: ActionType.ADD, data: option, triggerUpdate: true })
              e.stopPropagation()
            }}
            role="option"
            use="button"
            disabled={disabled || option.disabled}
          >
            {typeof children === 'function' ? children(option) : option.label}
          </Dropdown.Item>
        )}
      />
    )
  }

  const renderContent = () => {
    if (groups) {
      return groups.map(group => (
        <Dropdown.Group key={group.title}>
          <Box ml="s-16" mt="s-4">
            <Text color="grey-tone-50" fontSize="small">
              {group.title}
            </Text>
          </Box>

          {renderOptions(group.options)}
        </Dropdown.Group>
      ))
    }

    return <Dropdown.Group>{renderOptions(options)}</Dropdown.Group>
  }

  const handleSearch = debounce((val: string) => {
    if (useBuiltInSearch) {
      setSearch(val)
    } else {
      onSearch(val)
    }
  }, 250)

  return (
    <Wrapper
      onClick={() => {
        if (!disabled) {
          setIsOpen(!isOpen)
        }

        if (useBuiltInSearch) {
          setSearch('')
        } else {
          onSearch(search)
        }
      }}
      aria-labelledby={label}
    >
      <Box
        minHeight={58}
        bg={hasError ? Color.INPUT_ERROR : Color.GREY_TONE_8}
        px="s-16"
        pt="s-16"
        pb="10px"
        borderRadius="12px"
        disabled={asyncState !== 'ready' || disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        ref={fieldRef}
        opacity={disabled ? 0.5 : 1}
        data-name={name}
        data-testid={placeholder}
        aria-invalid={!!hasError}
        {...props}
      >
        <Flex width="100%" justifyContent="space-between">
          {renderValue()}

          <Flex alignSelf="flex-start">
            {clearable && !!value.size && (
              <ClearButton
                onClick={e => {
                  dispatchValue({
                    type: ActionType.CLEAR,
                    data: [],
                    triggerUpdate: true,
                  })
                  e.stopPropagation()
                }}
              />
            )}
            {renderIcon()}
            {isOpen ? (
              <ChevronUp color={Color.PRIMARY} />
            ) : (
              <ChevronDown color={Color.GREY_TONE_50} />
            )}
          </Flex>
        </Flex>
        <Dropdown
          open={isOpen}
          id={dropdownId}
          onClose={() => setIsOpen(false)}
          width={fieldWidth}
          data-testid="multiselect-dropdown"
        >
          <Dropdown.Group sticky>
            <Dropdown.Search
              placeholder="Search..."
              data-testid="multiselect-search"
              autoFocus
              onClick={e => {
                e.stopPropagation()
              }}
              onChange={(e: any) => {
                handleSearch(e.currentTarget.value)
              }}
            />
            {canCreateNew && (
              <HStack
                borderTopStyle="solid"
                borderTopWidth={1}
                borderTopColor={Color.GREY_10_50}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  height={optionHeight}
                >
                  <UILink
                    ml="s-16"
                    use={Link}
                    target="_blank"
                    to={getLocationDescriptor(
                      pathToUrl(selectorToCreateURL[selector as selectorKeys] || ''),
                    )}
                    color="blue"
                  >
                    <HStack space="s-8" align="center">
                      <Plus size={16} />
                      <Text>
                        Create new {selectorToItemName[selector as selectorKeys] || ''}
                      </Text>
                    </HStack>
                  </UILink>
                </Flex>
                <Spacer />
              </HStack>
            )}
          </Dropdown.Group>
          {renderContent()}
          <Dropdown.Group>
            {allowCustomOptions && (
              <Dropdown.Item
                onClick={e => {
                  dispatchValue({
                    type: ActionType.ADD,
                    data: {
                      label: search,
                      value: {
                        id: search,
                        name: search,
                      },
                    },
                    triggerUpdate: true,
                  })
                  e.stopPropagation()
                }}
                role="option"
                use="button"
                disabled={disabled}
              >
                {customOptionLabel || 'Add new tag:'} {search}
              </Dropdown.Item>
            )}
          </Dropdown.Group>
        </Dropdown>
      </Box>
      <BottomInputMessage hasError={hasError} message={message} />
    </Wrapper>
  )
}

export default NewMultiSelect
