import React from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CommonCardProps } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  getKpiGradeColumn,
  kpiCalibratedPerformanceColumn,
  kpiGenericNameColumn,
  kpiOwnerColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { usePersonalKPITable } from '@src/pages/EmployeeProfile/Preview/KPI/PersonalKPI'
import { GradesMapInterface } from '@src/utils/grades'
import isNumber from 'lodash/isNumber'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface KPIsCardInterface extends CommonCardProps {
  employee: EmployeeInterface
  gradesMap: GradesMapInterface
  setGoalsStat: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const getKpisRow: (
  gradesMap: GradesMapInterface,
  goalsEnabled: boolean,
) => RowInterface<KpiInterface> = (gradesMap, goalsEnabled) => ({
  disabled: data => data.target_status === Statuses.archived,
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })),
  cells: [
    {
      ...kpiGenericNameColumn,
      title: goalsEnabled ? 'Metric name' : 'KPI name',
      width: 300,
    },
    {
      ...kpiWeightColumn,
      width: 60,
    },
    {
      ...kpiOwnerColumn,
      width: 80,
    },
    {
      ...kpiCalibratedPerformanceColumn,
      title: 'Progress',
      width: 100,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})

const KPIsCardTableContent = ({
  employee,
  gradesMap,
  setGoalsStat,
}: KPIsCardInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycleId = 'reviews' in values ? values.reviews[0].cycle?.id : values.cycle?.id
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const table = usePersonalKPITable(
    employee,
    undefined,
    [
      {
        columnName: 'cycle__id',
        filters: cycleId
          ? [{ name: String(cycleId), id: cycleId }]
          : [{ name: '0', id: 0 }],
        nonResettable: true,
      },
    ],
    true,
    goalsEnabled,
  )

  if (isNumber(table.stats?.avg_performance)) {
    setGoalsStat(table.stats?.avg_performance)
  }

  return (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.EmployeePerformanceKPI}
          dataType={goalsEnabled ? 'Metrics' : 'KPI'}
          row={getKpisRow(gradesMap, goalsEnabled)}
          {...table}
          noDataMessage={`No ${goalsEnabled ? 'metrics' : 'KPI'} defined`}
          onRowClick={onKPITableRowClick}
          noReset
          hideCountAndButtonSection
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

interface KPIsCardTableProps
  extends Pick<KPIsCardInterface, 'gradesMap' | 'setGoalsStat'> {}

export const KPIsCardTable = connect(
  ({ gradesMap, setGoalsStat }: KPIsCardTableProps) => {
    const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
    const { data: employee } = useGetEmployeeWithManagerFilter(
      'reviewed_employee' in values
        ? values.reviewed_employee.id
        : values.reviews[0].reviewed_employee.id,
      'line_manager__id',
    )

    if (!employee) {
      return null
    }

    return (
      <KPIsCardTableContent
        employee={employee}
        gradesMap={gradesMap}
        setGoalsStat={setGoalsStat}
      />
    )
  },
)
