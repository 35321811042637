import React from 'react'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import {
  Color,
  Group,
  Text,
  Box,
  VStack,
  MoreBar,
  ExpandableCell,
  HStack,
  Icon,
} from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { EditButton } from '@src/features/SettingsButtons'
import { ActionToggleButton } from '@src/apps/People/Engagement/components/ActionToggleButton'
import {
  activateEngagementSurvey,
  archiveEngagementSurvey,
  cancelEngagementSurvey,
  unarchiveEngagementSurvey,
} from '@src/api/engagement'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getScheduleString } from '../../helpers'

export const SurveyPreview = () => {
  const { initialValues } = useLapeContext<EngagementSurveyInterface>()
  const permissions = initialValues.field_options?.permissions || []
  const globalPermissions = useSelector(selectPermissions)
  const canEdit = globalPermissions.includes(PermissionTypes.EngagementManagerPermissions)
  const canCancel = permissions.includes(PermissionTypes.CancelEngagementSurvey)
  const canArchive = permissions.includes(PermissionTypes.ArchiveEngagementSurvey)
  const showActionBar = canEdit || canCancel

  return (
    <PageBody>
      {showActionBar && (
        <Box mb="s-24">
          <MoreBar>
            <EditButton
              route={pathToUrl(ROUTES.FORMS.ENGAGEMENT.EDIT, {
                step: 'basic',
                id: initialValues.id,
              })}
              isVisible={canEdit}
            />
            {canCancel && (
              <ActionToggleButton<EngagementSurveyInterface>
                isActive={initialValues.status?.id !== 'inactive'}
                activeButton={{
                  text: 'Deactivate',
                  icon: 'LightningOff',
                  action: cancelEngagementSurvey,
                  successText: 'Survey was successfully deactivated',
                }}
                activeSubmit={{
                  title: 'Are you sure you want to deactivate this survey?',
                  subtitle:
                    'It will not be sent to any member of the audience. ' +
                    'You will still be able to reactivate it until the scheduled sending date.',
                }}
                inactiveButton={{
                  text: 'Reactivate',
                  icon: 'Retry',
                  action: activateEngagementSurvey,
                  successText: 'Survey was successfully reactivated',
                }}
                inactiveSubmit={{
                  title: 'Are you sure you want to reactivate this survey?',
                }}
              />
            )}
            {canArchive && (
              <ActionToggleButton<EngagementSurveyInterface>
                isActive={initialValues.status?.id !== 'archived'}
                activeButton={{
                  text: 'Archive',
                  icon: 'Archive',
                  action: archiveEngagementSurvey,
                  successText: 'Survey was successfully archived',
                }}
                activeSubmit={{
                  title: 'Are you sure you want to archive this survey?',
                }}
                inactiveButton={{
                  text: 'Unarchive',
                  icon: 'Unarchive',
                  action: unarchiveEngagementSurvey,
                  successText: 'Survey was successfully unarchived',
                }}
                inactiveSubmit={{
                  title: 'Are you sure you want to unarchive this survey?',
                }}
              />
            )}
          </MoreBar>
        </Box>
      )}
      <FormPreview title="General" data={initialValues}>
        <VStack space="s-16">
          <Group>
            <FormPreview.Details<EngagementSurveyInterface>
              title="Instructions"
              field="instructions"
              insert={data =>
                data.instructions ? (
                  <Box mt="s-8">
                    <HTMLEditor
                      value={initialValues.instructions}
                      readOnly
                      height="auto"
                      onChange={() => {}}
                      nonResizable
                      noPadding
                      fontSize="14px"
                    />
                  </Box>
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item
              title="Schedule"
              field="sent_every.name"
              insert={() => {
                return initialValues.sent_every?.id === 'manual' ? (
                  <Text>{initialValues.sent_every?.name}</Text>
                ) : (
                  <Text>{getScheduleString(initialValues)}</Text>
                )
              }}
            />
            <FormPreview.Item title="Owner" field="owner" type="employee" />
            <ExpandableCell>
              <ExpandableCell.Title>Eligibility</ExpandableCell.Title>
              <ExpandableCell.Content>
                {initialValues.audiences?.length
                  ? pluralize('group', initialValues.audiences.length, true)
                  : '-'}
              </ExpandableCell.Content>
              {!!initialValues.audiences?.length && (
                <ExpandableCell.Note>
                  <VStack space="s-8">
                    {initialValues.audiences.map(audience => (
                      <HStack key={audience.id} space="s-8" align="center">
                        <Box>
                          <Icon name="People" size={14} />
                        </Box>
                        <InternalLink
                          style={{ textDecoration: 'none' }}
                          to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, {
                            id: audience.object_id,
                          })}
                        >
                          <Text color={Color.FOREGROUND}>{audience.name}</Text>
                        </InternalLink>
                      </HStack>
                    ))}
                  </VStack>
                </ExpandableCell.Note>
              )}
            </ExpandableCell>
            <ExpandableCell>
              <ExpandableCell.Title>Questions</ExpandableCell.Title>
              <ExpandableCell.Content>
                {pluralize('question', initialValues.survey_questions?.length || 0, true)}
              </ExpandableCell.Content>
              {!!initialValues.survey_questions?.length && (
                <ExpandableCell.Note>
                  <VStack space="s-8">
                    {initialValues.survey_questions.map(surveyQuestion => (
                      <HStack key={surveyQuestion.id} space="s-8" align="center">
                        <Box>
                          <Icon name="HelpChatOutline" size={14} />
                        </Box>
                        <InternalLink
                          style={{ textDecoration: 'none' }}
                          to={pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, {
                            id: surveyQuestion.question.id,
                          })}
                        >
                          <Text color={Color.FOREGROUND}>
                            {surveyQuestion.question.question_text}
                          </Text>
                        </InternalLink>
                      </HStack>
                    ))}
                  </VStack>
                </ExpandableCell.Note>
              )}
            </ExpandableCell>
            <FormPreview.Item<EngagementSurveyInterface>
              title="Response deadline"
              field="response_deadline_days"
              insert={data => (
                <Text>
                  {data.response_deadline_days
                    ? pluralize('day', data.response_deadline_days, true)
                    : '-'}
                </Text>
              )}
            />
          </Group>
        </VStack>
      </FormPreview>
      <FormPreview title="Notifications" data={initialValues}>
        <Group>
          <FormPreview.Item title="Name" field="display_name" />
          <FormPreview.Details title="Description" field="description" />
          <FormPreview.Item title="Campaign" field="campaign.name" />
          <FormPreview.Item
            title="Completion time in minutes"
            field="completion_minutes"
          />
          <FormPreview.Item
            title="Slack notification enabled"
            type="boolean"
            field="slack_notification_enabled"
          />
        </Group>
      </FormPreview>
    </PageBody>
  )
}
