import React, { useContext, useMemo, useState } from 'react'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import { DeliverablesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/DeliverablesCard'
import { SkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/SkillsCard'
import { ManagerSkillsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/ManagerSkillsCard'
import { CultureValuesCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/CultureValuesCard'
import { BarRaiser } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/BarRaiser'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CriteriaAssessment,
  ReviewCategory,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { Feedback } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Feedback'
import { AdditionalQuestions } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/AdditionalQuestions'
import { Visibility } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Visibility'
import { SaveDraftButton } from '@src/pages/Forms/EmployeePerformanceLayout/SaveDraftButton'
import { SubmitButton } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'
import { PageActions } from '@components/Page/PageActions'
import { PerformanceHeader } from '@src/pages/Forms/EmployeePerformanceLayout/components/PerformanceHeader'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPIsCard } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/KPIsCard'
import { EmployeePerformanceInterface } from '@src/pages/Forms/EmployeePerformanceLayout/index'
import {
  getRecommendedGrade,
  RecommendedGradesInterface,
  useGetSegmentedDeliverablesEnabled,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import get from 'lodash/get'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { Statuses } from '@src/interfaces'
import { GradesPrefilledBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/RatingsPrefilledBanner'

export const RecommendedGradesContext = React.createContext<RecommendedGradesInterface>({
  recommendedGrade: null,
  grades: null,
})

export const useRecommendedGradesContext = () => {
  return useContext(RecommendedGradesContext)
}

export const CardItemContext = React.createContext<{
  currentItem: string | null
  setCurrentItem: React.Dispatch<React.SetStateAction<string | null>>
} | null>(null)

export const useCardItemContext = () => {
  return useContext(CardItemContext)
}

export const getRecommendationAndType = (values: ReviewScorecardInterface) => {
  const lmRecommendation = values.review_data.line_manager_extra_section
  const fmRecommendation = values.review_data.functional_manager_extra_section
  const peerRecommendation = values.review_data.peer_extra_section
  const probationPipRecommendation = values.review_data.recommendation_extra_section
  const obsoletePipRecommendation = values.review_data.pip_extra_section

  if (lmRecommendation) {
    return {
      recommendation: lmRecommendation,
      type: RecommendationTypes.LM,
      field: 'review_data.line_manager_extra_section',
    }
  }
  if (fmRecommendation) {
    return {
      recommendation: fmRecommendation,
      type: RecommendationTypes.FM,
      field: 'review_data.functional_manager_extra_section',
    }
  }
  if (peerRecommendation) {
    return {
      recommendation: peerRecommendation,
      type: RecommendationTypes.PEER,
      field: 'review_data.peer_extra_section',
    }
  }
  if (probationPipRecommendation) {
    return {
      recommendation: probationPipRecommendation,
      type: RecommendationTypes.PIP,
      field: 'review_data.recommendation_extra_section',
    }
  }
  if (obsoletePipRecommendation) {
    return {
      recommendation: obsoletePipRecommendation,
      type: RecommendationTypes.PIP_V1,
      field: 'review_data.pip_extra_section',
    }
  }

  return null
}

export const ScorecardContent = connect(
  ({ latestValues }: EmployeePerformanceInterface) => {
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const [submitChecked, setSubmitChecked] = useState(false)
    const [isHelpOpen, setHelpOpen] = useState<HelpTabs | undefined>()
    const [currentItem, setCurrentItem] = useState<string | null>(null)
    const recommendationSection = getRecommendationAndType(values)
    const { gradesMap: newGradesMap } = useGetReviewGradesMap()
    const gradesMap = values.grade_label_mapping || newGradesMap

    const contextValue = useMemo(
      () => ({ currentItem, setCurrentItem }),
      [currentItem, setCurrentItem],
    )

    const isSegmentedDeliverables = useGetSegmentedDeliverablesEnabled()
    const isPerfReview = values.category === ReviewCategory.Performance

    const isCriteriaAssessment = (data: unknown): data is CriteriaAssessment =>
      (data as CriteriaAssessment)?.items !== undefined
    const section = isCriteriaAssessment(
      values.review_data.functional_skills?.cards?.[0].sections?.[0],
    )
      ? values.review_data.functional_skills?.cards?.[0].sections?.[0]
      : undefined
    const hasSkillsToPrefill = !section
      ? false
      : !!section.items?.[0].previous_values?.[0]?.value
    const showBanner = isPerfReview && hasSkillsToPrefill

    const grades = getRecommendedGrade(!!isSegmentedDeliverables, latestValues)

    const user = useSelector(selectUser)
    const reviews = useGetNotCompletedReviews(user.id)
    const reviewsList = reviews?.data?.results?.filter(
      review => review.status === Statuses.pending || review.status === Statuses.draft,
    )

    if (recommendationSection && grades.recommendedGrade) {
      const recommendation = get(
        values,
        recommendationSection.field,
      )?.employee_project_performance

      if (recommendation && !recommendation.value) {
        recommendation.value = grades.recommendedGrade
      }
    }

    const employeePerformance =
      recommendationSection?.recommendation.employee_project_performance
    const employeePerformanceValue = employeePerformance?.value
    const gradeDifferExpectation =
      !!employeePerformanceValue &&
      !!grades.recommendedGrade &&
      employeePerformanceValue !== grades.recommendedGrade
    const missingFeedbackJustification =
      !employeePerformance?.justification && gradeDifferExpectation

    const onHelpTooltipClick = (helpTab: HelpTabs) => {
      if (isHelpOpen) {
        setHelpOpen(undefined)
      }
      setHelpOpen(helpTab)
    }

    const beforeSubmitCheck = () => {
      setSubmitChecked(missingFeedbackJustification)
      const checksPassed = !missingFeedbackJustification

      if (!checksPassed) {
        pushNotification({
          value: 'Please review the form, some justifications are missing',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.warning,
        })
      }
      return checksPassed
    }

    const commonProps = {
      onHelpClick: onHelpTooltipClick,
      gradesMap,
    }

    return (
      <RecommendedGradesContext.Provider value={grades}>
        <CardItemContext.Provider value={contextValue}>
          <PerformanceHeader isHelpOpen={isHelpOpen} setHelpOpen={setHelpOpen} />
          <VStack space="s-16">
            {showBanner && <GradesPrefilledBanner />}
            <KPIsCard {...commonProps} />
            <DeliverablesCard
              {...commonProps}
              showGoals={false}
              isSegmentedDeliverables={isSegmentedDeliverables}
            />
            <SkillsCard {...commonProps} />
            <ManagerSkillsCard {...commonProps} />
            <CultureValuesCard {...commonProps} />
            {recommendationSection && (
              <BarRaiser
                isViewMode={false}
                questions={
                  recommendationSection.recommendation.keeper_test_section.questions
                }
                type={recommendationSection.type}
                field={recommendationSection.field}
              />
            )}
            <AdditionalQuestions data={values.review_data} isViewMode={false} />
            <Feedback
              recommendationData={recommendationSection?.recommendation}
              type={recommendationSection?.type}
              showMissingJustificationError={
                submitChecked && missingFeedbackJustification
              }
              gradesMap={gradesMap}
            />
            <Visibility />
            <PageActions maxWidth="100%">
              <Flex gap="s-8" justifyContent="center">
                <SaveDraftButton reviewsList={reviewsList} />
                <SubmitButton
                  beforeSubmitCheck={beforeSubmitCheck}
                  reviewsList={reviewsList}
                />
              </Flex>
            </PageActions>
          </VStack>
        </CardItemContext.Provider>
      </RecommendedGradesContext.Provider>
    )
  },
)
