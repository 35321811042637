import React, { useState } from 'react'
import {
  activateEngagementSurvey,
  engagementSurveyFormRequests,
  testEngagementSurvey,
  useGetEngagementSurvey,
} from '@src/api/engagement'
import { useParams } from 'react-router-dom'
import Loader from '@src/components/CommonSC/Loader'
import {
  Widget,
  Text,
  Token,
  Flex,
  BREAKPOINTS,
  Button,
  useStatusPopup,
  StatusPopup,
  MoreBar,
} from '@revolut/ui-kit'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { FormattedMessage } from 'react-intl'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { Statuses } from '@src/interfaces'
import { Timing } from '@src/pages/Forms/Engagement/summaryComponents/Timing'
import { Questions } from '@src/pages/Forms/Engagement/summaryComponents/Questions'
import { Audience } from '@src/pages/Forms/Engagement/summaryComponents/Audience'
import { Notification } from '@src/pages/Forms/Engagement/summaryComponents/Notification'
import { engagementConfig } from '../common/checkpointsConfig'
import { completeOnboardingCheckpoint } from '@src/api/onboardingChecklistV2'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

export const EngagementSetup = () => {
  const { id } = useParams<{ id?: string }>()
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  const { data: surveyData, isLoading } = useGetEngagementSurvey(id)

  const handleTestSurvey = async () => {
    setIsPending(true)
    try {
      const { data } = await testEngagementSurvey(Number(id))
      navigateTo(
        `${pathToUrl(ROUTES.ENGAGEMENT_PAGE, undefined, {
          survey_id: String(data?.id),
          is_test: 'true',
        })}`,
      )
    } finally {
      setIsPending(false)
    }
  }

  const onEdit = async (step?: EngagementEditStep) => {
    if (!surveyData?.id) {
      return
    }
    navigateTo(
      pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE, {
        step,
        id: surveyData?.id,
      }),
    )
  }

  const handleLaunchSurvey = async () => {
    if (!surveyData?.id) {
      return
    }
    setIsPending(true)
    try {
      await activateEngagementSurvey(surveyData.id)
      await completeOnboardingCheckpoint(engagementConfig.category)
      workspaceLocalStorage.removeItem(
        LOCAL_STORAGE.ONBOARDING_ENGAGEMENT_CURRENT_DRAFT_ID,
      )
      statusPopup.show(
        <StatusPopup
          variant="success-result"
          onClose={() => {
            statusPopup.hide()
            navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
          }}
        >
          <StatusPopup.Title>Congratulations!</StatusPopup.Title>
          <StatusPopup.Description>
            You've set up your first survey
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              onClick={() => {
                statusPopup.hide()
                navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
              }}
            >
              <FormattedMessage
                id="onboardingV2.general.goToDashboard"
                defaultMessage="Go to Dashboard"
              />
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const handleSaveDraft = async () => {
    if (!surveyData?.id) {
      return
    }
    setIsPending(true)
    try {
      await engagementSurveyFormRequests.update(
        { status: { id: Statuses.inactive, name: Statuses.inactive } },
        { id: String(surveyData.id) },
      )
      navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  if (!isLoading && !surveyData) {
    return <InternalRedirect to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD} />
  }

  return (
    <OnboardingIntro>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Widget p="s-16">
            <Flex flexDirection="column" width="100%" gap="s-4">
              <Text variant="h5">{surveyData.title}</Text>
              <Text color={Token.color.greyTone50} pb="s-16">
                Review, test and customise the engagement survey we tailored for you
              </Text>
              <MoreBar>
                <MoreBar.Action useIcon="Pencil" onClick={() => onEdit('basic')}>
                  Customise
                </MoreBar.Action>
                <MoreBar.Action
                  disabled={isPending}
                  useIcon="Play"
                  onClick={handleTestSurvey}
                >
                  Test survey
                </MoreBar.Action>
              </MoreBar>
            </Flex>
          </Widget>
          <Timing {...surveyData} onEdit={() => onEdit('basic')} />
          <Questions {...surveyData} onEdit={() => onEdit('questions')} />
          <Audience {...surveyData} onEdit={() => onEdit('audience')} />
          <Notification {...surveyData} onEdit={() => onEdit('notifications')} />

          {surveyData?.id && (
            <PageActions maxWidthMd={BREAKPOINTS.lg}>
              <Button onClick={handleSaveDraft} variant="secondary" pending={isPending}>
                Save as draft
              </Button>
              <Button onClick={handleLaunchSurvey} pending={isPending}>
                Launch survey
              </Button>
            </PageActions>
          )}
        </>
      )}
    </OnboardingIntro>
  )
}
