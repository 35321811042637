import React, { useState } from 'react'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
} from '@src/interfaces/hiringProccess'
import { pathToUrl } from '@src/utils/router'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import {
  ActionButton,
  BREAKPOINTS,
  Box,
  Button,
  HStack,
  Portal,
  StatusPopup,
  Subheader,
  TableWidget,
  VStack,
  useStatusPopup,
} from '@revolut/ui-kit'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import {
  createSpecialisationHiringProcessRequest,
  specialisationArchiveHiringProcessRound,
  updateSpecialisationHiringProcess,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useParams } from 'react-router-dom'
import { SpecialisationHiringProcessParams } from '@src/pages/Forms/SpecialisationHiringProcess/types'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import HiringStageSpecialisationPreviewForm from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringStagePreviewForm'
import { HiringProcessTable } from '../SpecialisationForm/HiringProcess/HiringProcessTable'
import { Statuses } from '@src/interfaces'
import { AddFromHiringStagesBank } from '@src/pages/Forms/SpecialisationHiringProcess/AddFromHiringStagesBank'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import PageLoading from '@src/components/PageLoading/PageLoading'

export const GeneralForm = () => {
  const { errors, loading, values, submit, initialValues } =
    useLapeContext<SpecialisationHiringProcess>()
  const [showHiringStageDetails, setShowHiringStageDetails] =
    useState<HiringProcessInterface>()
  const [showHiringStagesBank, setShowHiringStagesBank] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const statusPopup = useStatusPopup()
  const isDraft = initialValues.status === Statuses.draft
  const backUrl = pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
    id: values.specialisation?.id,
    subtab: isDraft ? undefined : String(values.id),
  })
  const handleDelete = async (hiringProcessRound: HiringProcessInterface) => {
    const stages = values.hiring_process_stages
    try {
      values.hiring_process_stages = stages?.filter(
        ({ id }) => id !== hiringProcessRound.id,
      )
      await specialisationArchiveHiringProcessRound(hiringProcessRound.id)
    } catch {
      values.hiring_process_stages = stages
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error deleting hiring process rounds
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const navigateToHiringStageForm = (hiringStageId?: number) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.HIRING_STAGE, {
        id: hiringStageId,
        specialisationId: values.specialisation.id,
        hiringProcessId: values.id,
      }),
      {
        specialisation: values.specialisation,
      },
    )
  }
  const handleOrderChange = async (hiringProcessRounds: HiringProcessInterface[]) => {
    const stages = values.hiring_process_stages
    try {
      values.hiring_process_stages = hiringProcessRounds
      await updateSpecialisationHiringProcess(values.id, values.specialisation.id, values)
    } catch {
      values.hiring_process_stages = stages
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            There was an error changing the order of hiring process rounds
          </StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }
  const handleShowDetails = (hiringProcessRound: HiringProcessInterface) => {
    setShowHiringStageDetails(hiringProcessRound)
    setShowHiringStagesBank(false)
  }
  const actions = (
    <HStack mb="s-16" gap="s-16">
      <ActionButton
        useIcon="Library"
        pending={loadingSubmit}
        onClick={() => {
          setShowHiringStagesBank(!showHiringStagesBank)
          setShowHiringStageDetails(undefined)
        }}
      >
        Select from hiring stages bank
      </ActionButton>
      <ActionButton
        useIcon="Plus"
        onClick={async () => {
          // This try catch block is so order of stages, name and description is saved
          // before navigating to stages form.
          // Errors on this endpoint could be because of no hiring stages or no name.
          // Since user clicks this button to create stages its ok to ignore no hiring
          // stages error.
          // If user removes the name then its acceptable to ignore error since user can
          // correct it after adding stages and will get the error in the name field when
          // submitting this form.
          try {
            await updateSpecialisationHiringProcess(
              values.id,
              values.specialisation.id,
              values,
            )
          } finally {
            navigateToHiringStageForm()
          }
        }}
        pending={loadingSubmit}
      >
        Create new stage
      </ActionButton>
    </HStack>
  )
  if (loading) {
    return <PageLoading />
  }
  return (
    <>
      {showHiringStagesBank && (
        <AddFromHiringStagesBank
          pending={loadingSubmit}
          onAddHiringStagesFromBank={hiringStages => {
            values.hiring_process_stages = [
              ...(values.hiring_process_stages ?? []),
              ...hiringStages,
            ]
          }}
          onClose={() => {
            setShowHiringStagesBank(false)
          }}
        />
      )}
      {showHiringStageDetails && (
        <Portal>
          <HiringStageSpecialisationPreviewForm
            id={showHiringStageDetails.id}
            type="specialisation-hiring-process"
            refetchOnFocus
            onClose={() => {
              setShowHiringStageDetails(undefined)
            }}
            onSuccessDelete={() => {
              values.hiring_process_stages = values.hiring_process_stages?.filter(
                ({ id }) => id !== showHiringStageDetails.id,
              )
              setShowHiringStageDetails(undefined)
            }}
          />
        </Portal>
      )}
      <PageWrapper>
        <PageHeader
          title={`${isDraft ? 'Create' : 'Edit'} hiring process`}
          backUrl={backUrl}
          routerAction={PageHeaderRouterAction.navigate}
        />
        <PageBody maxWidth="100%">
          <Box maxWidth={{ all: '100%', md: BREAKPOINTS.md }}>
            <Subheader>
              <Subheader.Title>Hiring process details</Subheader.Title>
            </Subheader>
            <VStack gap="s-16">
              <LapeNewInput label="Name" name="name" placeholder="name" required />
              <LapeNewInput label="Note" name="description" placeholder="note" required />
            </VStack>
            <Subheader>
              <Subheader.Title>Hiring stages</Subheader.Title>
            </Subheader>
          </Box>
          <Box width="100%">
            <TableWidget>
              <TableWidget.Actions>{actions}</TableWidget.Actions>
              <TableWidget.Table>
                <HiringProcessTable
                  hiringProcessRounds={values.hiring_process_stages}
                  hiringProcessErrors={errors.hiring_process_stages}
                  loading={!!loading}
                  onDelete={handleDelete}
                  onOrderChange={handleOrderChange}
                  onRowClick={handleShowDetails}
                />
              </TableWidget.Table>
            </TableWidget>
          </Box>
        </PageBody>
        <PageActions>
          <Button use={InternalLink} variant="secondary" to={backUrl}>
            Back
          </Button>
          <NewSaveButtonWithPopup
            hideWhenNoChanges={false}
            pending={loading || loadingSubmit}
            useValidator
            successText="Hiring process saved successfully"
            onClick={async () => {
              setLoadingSubmit(true)
              values.status = Statuses.active
              const res = await submit()
              setLoadingSubmit(false)
              return res
            }}
            onAfterSubmit={() => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                  id: values.specialisation?.id,
                  subtab: String(values.id),
                }),
              )
            }}
            errorHandler={() => {
              setLoadingSubmit(false)
            }}
          />
        </PageActions>
      </PageWrapper>
    </>
  )
}

export const General = () => {
  const params = useParams<SpecialisationHiringProcessParams>()
  return (
    <Form
      api={createSpecialisationHiringProcessRequest(params.specialisationId)}
      forceParams={{
        id: params.hiringProcessId,
      }}
      refetchOnFocus
      disableLoading
    >
      <GeneralForm />
    </Form>
  )
}
